// Projects.js
import React from 'react';
import { motion } from 'framer-motion';

const Projects = () => {
  const projectsData = [
    {
      name: 'Emerald Dam',
      imageUrl: 'https://i.pinimg.com/originals/ee/f9/1e/eef91e9330b18d0d6a2b5774d153a8f8.jpg', // Replace with your project image URL
    },
    {
      name: 'Upper Bhavani Dam',
      imageUrl: 'https://assets.architecturaldigest.in/photos/60083c6d94238cf91d10890c/16:9/w_2560%2Cc_limit/Chennai-home-interior-design-photos-1366x768.jpg',
    },
    {
      name: 'Cauvery Bridge',
      imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe-TUGk4o6e1yDf3QSeCjolIKEN3QiMvGMviDKs_aBhADq7GxLmJx3seQqWIp-aLIWReM&usqp=CAU',
    },
    {
      name: 'Periyar Canal',
      imageUrl: 'https://i.pinimg.com/originals/ee/f9/1e/eef91e9330b18d0d6a2b5774d153a8f8.jpg', // Replace with your project image URL
    },
    {
      name: 'Vattamalai Bridge',
      imageUrl: 'https://assets.architecturaldigest.in/photos/60083c6d94238cf91d10890c/16:9/w_2560%2Cc_limit/Chennai-home-interior-design-photos-1366x768.jpg',
    },
    {
      name: 'Collector Office, Erode',
      imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe-TUGk4o6e1yDf3QSeCjolIKEN3QiMvGMviDKs_aBhADq7GxLmJx3seQqWIp-aLIWReM&usqp=CAU',
    },
  ];

  return (
    <section className="projects-section py-16">
      <div className="container mx-auto">
        <h2 className="text-5xl font-normal mb-8 text-center">Our Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 pl-20 pr-20 ">
          {projectsData.map((project, index) => (
            <motion.div
              key={index}
              className="project-card relative overflow-hidden rounded-md shadow-md"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <div className="relative">
                <img
                  src={project.imageUrl}
                  alt={project.name}
                  className="w-full h-96 object-cover"
                />
                <motion.div
                  className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100"
                >
                  <h3 className="text-white text-xl">{project.name}</h3>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
