import React from 'react';
import { motion } from 'framer-motion';
import '../App.css';
import pdf from '../work.pdf'

const Services = () => {
  const servicesData = [
    'Strategic Definition',
    'Design & Build',
    'Technical Design & Construction',
    'Interior Designing',
    '3D Visualization',
    'Execution & Handover',
  ];

  const openPdf = () => {
    // Replace 'path-to-your-pdf.pdf' with the actual path to your PDF file
    window.open(pdf, '_blank');
  };


  return (
    <section className="services-section py-16">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center">Our Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pl-10 pr-10">
          {servicesData.map((service, index) => (
            <motion.div
              key={index}
              className="p-6 h-36 rounded-lg shadow-md text-center relative cursor-pointer bg-gray-200 text-gray-800"
              whileHover={{ backgroundColor: '#f0f0f0' }}
            >
              <motion.div className="text-5xl font-normal text-center pb-5" style={{ color: '#bbbbbb' }} whileHover={{color: "#00000"}}>
                {index + 1}
              </motion.div>
              <h3 className=" text-xl font-bold mb-2">{service}</h3>
            </motion.div>
          ))}
        </div>
      </div>

      <div className='text-center p-5'>
        <button className='bg-neutral-200 rounded-2xl p-6 text-gray-700 mx-auto' onClick={openPdf}>View full <br/> Work stages</button>
      </div>
    </section>
  );
};

export default Services;