// Gallery.js

import React, { useState } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../App.css";
import Navbar2 from "./Navbar2";

// Assuming your images are in the same directory as Gallery.js
import image1 from "../pics/1 (15).JPG";
import image2 from "../pics/1 (17).JPG";
import image3 from "../pics/1 (18).JPG";
import image4 from "../pics/1 (20).JPG";
import image5 from "../pics/1 (30).JPG";

import image6 from "../pics/1 (1).JPG";
import image7 from "../pics/1 (2).JPG";
import image8 from "../pics/1 (3).JPG";
import image9 from "../pics/1 (4).JPG";
import image10 from "../pics/1 (5).JPG";
import image11 from "../pics/1 (6).JPG";
import image12 from "../pics/1 (7).JPG";
import image13 from "../pics/1 (8).JPG";
import image14 from "../pics/1 (9).JPG";
import image15 from "../pics/1 (10).JPG";
import image16 from "../pics/1 (11).JPG";
import image17 from "../pics/1 (12).JPG";
import image18 from "../pics/1 (13).JPG";
import image19 from "../pics/1 (14).JPG";
import image20 from "../pics/1 (16).JPG";
import image21 from "../pics/1 (19).JPG";
import image22 from "../pics/1 (21).JPG";
import image23 from "../pics/1 (22).JPG";
import image24 from "../pics/1 (23).JPG";
import image25 from "../pics/1 (24).JPG";
import image26 from "../pics/1 (25).JPG";
import image27 from "../pics/1 (26).JPG";
import image28 from "../pics/1 (27).JPG";
import image29 from "../pics/1 (28).JPG";
import image30 from "../pics/1 (29).JPG";
import image31 from "../pics/1 (31).JPG";
import image32 from "../pics/1 (32).JPG";
import image33 from "../pics/1 (33).JPG";
import image34 from "../pics/1 (34).JPG";
import image35 from "../pics/1 (35).JPG";
import image36 from "../pics/1 (36).JPG";

const images = [
  { url: image1, alt: "Image 1" },
  { url: image2, alt: "Image 2" },
  { url: image3, alt: "Image 3" },
  { url: image4, alt: "Image 4" },
  { url: image5, alt: "Image 5" },

  // Add more images as needed
];

const images2 = [
  { url: image6, alt: "Image 6" },
  { url: image7, alt: "Image 7" },
  { url: image8, alt: "Image 8" },
  { url: image9, alt: "Image 9" },
  { url: image10, alt: "Image 10" },
  { url: image11, alt: "Image 11" },
  { url: image12, alt: "Image 12" },
  { url: image13, alt: "Image 13" },
  { url: image14, alt: "Image 14" },
  { url: image15, alt: "Image 15" },
  { url: image16, alt: "Image 16" },
  { url: image17, alt: "Image 17" },
  { url: image18, alt: "Image 18" },
  { url: image19, alt: "Image 19" },
  { url: image20, alt: "Image 20" },
  { url: image21, alt: "Image 21" },
  { url: image22, alt: "Image 22" },
  { url: image23, alt: "Image 23" },
  { url: image24, alt: "Image 24" },
  { url: image25, alt: "Image 25" },
  { url: image26, alt: "Image 26" },
  { url: image27, alt: "Image 27" },
  { url: image28, alt: "Image 28" },
  { url: image29, alt: "Image 29" },
  { url: image30, alt: "Image 30" },
  { url: image31, alt: "Image 31" },
  { url: image32, alt: "Image 32" },
  { url: image33, alt: "Image 33" },
  { url: image34, alt: "Image 34" },
  { url: image35, alt: "Image 35" },
  { url: image36, alt: "Image 36" },
];

const Gallery = () => {
    const [currentPage, setCurrentPage] = useState(1);
  const [modalImage, setModalImage] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of slides to show on mobile
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust as needed
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const itemsPerPage = 5; // Set the number of images to show per page
  const totalPages = Math.ceil(images2.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentImages = images2.slice(startIndex, endIndex);

  return (
    <>
      <Navbar2 />
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Slider {...settings} className=" w-5/6">
          {images.map((image, index) => (
            <div key={index} className="p-2">
              <motion.img
                src={image.url}
                alt={image.alt}
                className="w-full h-auto rounded-md cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal(image)}
              />
            </div>
          ))}
        </Slider>

        <div className="mt-8 flex flex-wrap justify-center gap-4">
          {/* Additional sliding image tiles below */}
          {currentImages.map((image, index) => (
            <div key={index} className="p-2 max-h-64">
              <motion.img
                src={image.url}
                alt={image.alt}
                className="w-full h-auto max-h-64 rounded-md cursor-pointer"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => openModal(image)}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4 flex justify-center">
        {/* Pagination buttons */}
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            className={`mx-1 px-3 py-1 ${
              currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-300"
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>

      {modalImage && (
        <motion.div
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="relative max-w-screen-md max-h-screen-md w-full h-full">
            <span
              className="text-white absolute top-4 right-4 cursor-pointer"
              onClick={closeModal}
            >
              Close
            </span>
            <motion.img
              src={modalImage.url}
              alt={modalImage.alt}
              className="w-full h-full object-contain rounded-md"
            />
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Gallery;
