// Contact.js
import React from "react";

const Contact = () => {
  return (
    <section className="contact-section text-white sm:py-16 pl-5 pr-5">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between">
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 lg:pl-8">
          <h2 className="text-3xl font-bold mb-8 text-center lg:text-left">
            Contact Us
          </h2>
          <form
            action="https://formspree.io/f/mbjnjpvd"
            method="POST"
            className="max-w-md mx-auto lg:mx-0"
          >
            <label className="block mb-4">
              <span className="text-lg">First Name: *</span>
              <input
                type="text"
                name="first_name"
                required
                className="w-full mt-2 p-2 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </label>
            <label className="block mb-4">
              <span className="text-lg">Last Name:</span>
              <input
                type="text"
                name="last_name"
                className="w-full mt-2 p-2 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </label>
            <label className="block mb-4">
              <span className="text-lg">Email: *</span>
              <input
                type="email"
                name="email"
                required
                className="w-full mt-2 p-2 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </label>
            <label className="block mb-4">
              <span className="text-lg">Subject:</span>
              <input
                type="text"
                name="subject"
                className="w-full mt-2 p-2 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500"
              />
            </label>
            <label className="block mb-4">
              <span className="text-lg">Message: *</span>
              <textarea
                name="message"
                required
                className="w-full mt-2 p-2 border border-gray-600 rounded-md focus:outline-none focus:border-blue-500 text-black"
              ></textarea>
            </label>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
            >
              Send
            </button>
          </form>
        </div>
        <div className="w-full lg:w-1/2 lg:pl-8 mt-8 lg:mt-0">
          <div className="mb-4">
            <h3 className="text-lg font-bold">Head Office</h3>
            <p>18/1 Jaganathan layout,</p>
            <p>Mangalam road,</p>
            <p>Karumpalayam, Tirupur</p>
          </div>
          <br />
          <br />

          <div className="mb-4">
            <h3 className="text-lg font-bold">Contact Information</h3>
            <p>Email: keerthivarman24@gmail.com</p>
            <p>Tel: +91 86106 56393</p>
          </div>
          <br />
          <br />

          <div className="mb-4">
            <h3 className="text-lg font-bold">Employment</h3>
            <p>
              To apply for a job with ERR Associates, <br />
              please send a cover letter together with your
              <br /> C.V. to:{" "}
              <a href="mailto:keerthivarman24@gmail.com">
                keerthivarman24@gmail.com
              </a>
            </p>
          </div>
          <br />
          <br />

          <div>
            <h3 className="text-lg font-bold">Get a Quote</h3>
            <p>Call: +91 86106 56393</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
