// Home.js
import Spline from "@splinetool/react-spline";
import React from "react";
import { motion} from "framer-motion";
import "../App.css";
import bg from "../bg.jpg";
import logo from "../logo.png";
import Navbar from "./Navbar";
import Services from "./Services";
import About from "./About";
import Projects from "./Projects";
import ScrollDownButton from "./ScrollDownButton";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => {
  const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  

  return (
    <>
      <Navbar />

      <motion.section
        className="bg-cover bg-center h-screen flex items-center justify-center bg-fixed"
        style={{ backgroundImage: `url(${bg})` }}
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        transition={{ duration: 1.5 }}
      >
        <motion.div
      initial={{ opacity: 0, y: 20 }} // Initial state
      animate={{ opacity: 1, y: 0 }}  // Animation state
      transition={{ duration: 1 }}    // Animation duration
    >
        <div className="head-text text-white text-center">
          <h1 className=" text-5xl sm:text-9xl font-bold mb-4 pr-10">
            Designing
            <br />
            the future
          </h1>
          <p className=" text-sm sm:text-xl font-normal ">
            Crafting the Architectural Spaces of Your Dreams
          </p>
        </div></motion.div>
        <div className="absolute pt-24 bottom-0 right-15 size-20">
          <ScrollDownButton />{" "}
        </div>
      </motion.section>

      <Services></Services>
      <About></About>

      <div className="stats-container text-white p-6 md:p-10 rounded-md shadow-md">
  <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-16">
    <div className="stat-item text-center">
      <div className="text-4xl md:text-6xl font-bold mb-1">1957</div>
      <div>Since</div>
    </div>
    <div className="stat-item text-center">
      <div className="text-4xl md:text-6xl font-bold mb-1">100%</div>
      <div>Customer Satisfaction</div>
    </div>
    <div className="stat-item text-center">
      <div className="text-4xl md:text-6xl font-bold mb-1">10+</div>
      <div>Projects Completed</div>
    </div>
  </div>
</div>


      <Projects />

      <div className="container mx-auto p-4">
      <p className="text-6xl text-center font-bold pb-10"> Pricing </p>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-1 items-center justify-center">
          <motion.div className="card rounded-xl shadow-md p-4 max-w-full sm:max-w-80 ml-0 sm:ml-40" whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.1 }}
              >
            <h2 className="text-2xl font-semibold mb-2 ml-4 mt-6">Standard</h2>
            <p className=" text-6xl font-bold ml-4">
              ₹100<span className=" text-xl relative bottom-2"> /Sq.Ft</span>
            </p>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Architectural Design</p>
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">MEP Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Bill of Quantities</p>{" "}
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Interior Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="12"
                viewBox="0 0 384 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                />
              </svg>
              <p className="pl-5">Landscape Design</p>{" "}
            </span>{" "}
            <br />
            <div className=" align-middle text-center bg-white rounded-3xl text-2xl text-black p-3 mb-5"> Upto <span className=" text-4xl font-extrabold">2500</span> Sq.Ft </div>
          </motion.div>

          <motion.div className="card rounded-xl shadow-md p-4 max-w-full sm:max-w-80 ml-0 sm:ml-20 border border-amber-200 relative" whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.1 }}>
            <div  className="text-xl font-medium bg-amber-200 max-w-fit rounded-3xl p-1 text-black mb-2 absolute top-2 right-2">
            <h2>#bestdeal</h2></div>
            <h2 className="text-2xl font-semibold mb-2 ml-4 mt-6">Premium</h2>
            <p className=" text-6xl font-bold ml-4">
              ₹125<span className=" text-xl relative bottom-2"> /Sq.Ft</span>
            </p>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Architectural Design</p>
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">MEP Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Bill of Quantities</p>{" "}
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Interior Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="12"
                viewBox="0 0 384 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                />
              </svg>
              <p className="pl-5">Landscape Design</p>{" "}
            </span>{" "}
            <br />
            <div className=" align-middle text-center bg-white rounded-3xl text-2xl text-black p-3 mb-5"> Upto <span className=" text-4xl font-extrabold">4000</span> Sq.Ft </div>
          </motion.div>


          <motion.div className="card rounded-xl shadow-md p-4 max-w-full sm:max-w-80 mr-0 sm:mr-10" whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.1 }}>
            <h2 className="text-2xl font-semibold mb-2 ml-4 mt-6">Elite</h2>
            <p className=" text-6xl font-bold ml-4">
              ₹150<span className=" text-xl relative bottom-2"> /Sq.Ft</span>
            </p>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Architectural Design</p>
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">MEP Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Bill of Quantities</p>{" "}
            </span>
            <br />
            <span className="inline-flex items-center pb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Interior Design</p>{" "}
            </span>{" "}
            <br />
            <span className="inline-flex items-center pb-7">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-4"
                height="16"
                width="14"
                viewBox="0 0 448 512"
              >
                <path
                  opacity="1"
                  fill="#fff"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                />
              </svg>
              <p className="pl-5">Landscape Design</p>{" "}
            </span>{" "}
            <br />
            <div className=" align-middle text-center bg-white rounded-3xl text-2xl text-black p-3 mb-5"> Above <span className=" text-4xl font-extrabold">4000</span> Sq.Ft </div>
          </motion.div>


        </div>

        <p className="text-xl text-center font-normal pt-10"> Your Vision, Our Blueprint </p>

      </div>

      <Contact></Contact>
      <Footer></Footer>
    </>
  );
};

export default Home;
