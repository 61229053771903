// ScrollDownButton.js
import React, { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { motion, useAnimation } from 'framer-motion';


const ScrollDownButton = () => {

  const [isScrolling, setIsScrolling] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is scrolling
      if (window.scrollY > 50) {
        if (!isScrolling) {
          // Start the bouncing animation
          setIsScrolling(true);
          controls.start({
            y: [0, -10, 0], // Bounce animation
            transition: { duration: 1.5, repeat: Infinity },
          });
        }
      } else {
        // Stop the bouncing animation when the user is not scrolling
        if (isScrolling) {
          setIsScrolling(false);
          controls.stop();
        }
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolling, controls]);

  return (
    <ScrollLink
      to="services-section" // Replace with the target section ID
      smooth={true}
      duration={1000}
    >
       <motion.div
      className="scroll-down-button"
      animate={controls}
      style={{
        position: 'relative',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        cursor: 'pointer',
      }}
    >
      <div className="scroll-down-btn size-10">
        <img src="https://www.freeiconspng.com/uploads/white-down-arrow-png-2.png" alt="Scroll Down" />
      </div></motion.div>
    </ScrollLink>
  );
};

export default ScrollDownButton;
