// About.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../App.css";


const About = () => {
  const [isTamil, setIsTamil] = useState(false);

  const toggleLanguage = () => {
    setIsTamil(!isTamil);
  };

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
       <section className="background about-section py-16" style={{
        backgroundPosition: `${20 + mousePosition.x / 50}% ${10 + mousePosition.y / 50}%,
          right ${70 - mousePosition.x / 50}%,
          ${5 + mousePosition.x / 100}% ${80 + mousePosition.y / 100}%`
      }}>
      
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="text-3xl font-bold mb-8 text-center">About Us</h2>
          <div className="max-w-3xl">
            <div className=" pl-10 sm:pl-0 ">
              <button
                className="rounded-full bg-amber-50 text-gray-800 py-2 my-5 px-4 mr-2 focus:outline-none"
                onClick={() => setIsTamil(false)}
              >
                English
              </button>
              <button
                className="rounded-full bg-amber-50 text-gray-800 py-2 my-5 px-4 focus:outline-none"
                onClick={() => setIsTamil(true)}
              >
                தமிழ்
              </button>

              <p>
                {isTamil ? (
                  <>
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        ERR கட்டமைப்புகள், 1957 இ.ஆர். ரங்கசாமி மூலம் துவக்கம்
                        செய்யப்பட்டு, குறுகிய தொழில் நுட்பமானது மற்றும்
                        கட்டமைப்பு துறையில் ஒரு அற்புதமான நிறுவனமாக வளர்ந்தது.
                        <br />
                        முதலில் சிறிய அளவில் திட்டங்களுக்கு கவனம் கொண்டு, ERR
                        கட்டமைப்புகள் மேம்பட்ட தொழில்துறைகள் மதிப்புமிக்க திறனை
                        அளித்தன.
                      </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        ERR கட்டமைப்புகள் அனுபவம் மற்றும் நிபுணத்தின் வலிமையை
                        பெற்று, அவர்கள் வணிக துறைகளுக்கு உருவானதை கட்டுமானமாகக்
                        கொண்டனர்.
                        <br />
                        <br />
                        ERR கட்டமைப்புகளின் தொடக்கத்தில் இருந்து கட்டமைப்பு
                        துறையில் முக்கிய விளைவாக மாற்றம் காட்டுகின்றது மட்டும்
                        அல்லது அவர்கள் செயல்படுத்தும் போக்குகளுக்கு சமூகம்
                        மற்றும் கட்டுமான வளர்ச்சிக்கு திருப்தியை கொண்டு உள்ளது
                        என்பதையும் நம்புகின்றது.
                        <br />
                      </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        E. R. R. மோகன் 23 வயதில் ERR கட்டமைப்பின் உடனடியாக
                        முக்கிய அதிகாரியாக மாறியது, நிறுவனத்தின் வரலாற்றில்
                        முக்கியமான ஒரு அதிகாரத்திற்கு அடங்கியது. அவர் தந்தையின்
                        இடையே நடந்துகொள்ளும் போது, மோகன் அதிசயமான
                        முக்கியத்தையும் வணிக புரிந்தும் காட்டினார்.
                        </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        அவருடைய சிறு வயதில் மோகன் நிறுவனத்தை முன்னேற்றி அதனை
                        முன்னேற்ற மக்களை பற்றிய அற்புதமான அறிவுக்குப் பகிராதவனாக
                        உள்ளார். அவருடைய முன்னோக்கி ஆதரவாக, ERR கட்டமைப்புகள்
                        இரண்டு அபார்ட்மெண்ட் காம்ப்ளெக்ஸ் மட்டும் அல்லது இரண்டு
                        வணிக காம்ப்ளெக்ஸ்களையும் முடித்ததன் மூலம், மோகனின்
                        நிறுவனத்தின் போர்ட்போலியோயிடி வளர்ச்சிக்கு உறுதியாக
                        சாதனை செய்துள்ளது.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p className=" text-left">
                        ERR Constructions, established by E.R. Rangasamy in
                        1957, began as a humble venture and grew into a renowned
                        construction company over the years. Initially focused
                        on small-scale projects, ERR Constructions steadily
                        gained a reputation for delivering quality workmanship
                        and adhering to timelines.{" "}
                      </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        As ERR Constructions gained experience and expertise,
                        they expanded their portfolio to encompass commercial
                        and infrastructure projects, solidifying their position
                        in the construction industry.
                        <br />
                        The company’s commitment to excellence and dedication to
                        meeting client requirements enabled them to secure
                        numerous prestigious projects. Their expertise in
                        project management, construction engineering, and
                        innovative solutions became their hallmark.
                      </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        Throughout its history, ERR Constructions completed a
                        diverse array of projects, including residential
                        complexes, commercial buildings, educational
                        institutions, hospitals, and infrastructure
                        developments. Notable landmarks and successful projects
                        have dotted various cities, leaving a lasting legacy of
                        their craftsmanship and attention to detail.
                        <br />
                        <br />
                        ERR Constructions’ journey from its inception to
                        becoming a key player in the construction industry
                        reflects not only their commitment to completing
                        projects but also their dedication to contributing
                        significantly to the societal and infrastructural
                        development of the regions they operate in.
                      </p>
                    </div>
                    <br />
                    <div className="border border-solid p-5 sm:max-w-xl max-w-64 rounded-xl">
                      <p>
                        ERR Constructions’ transition into leading the
                        construction industry was marked by the appointment of
                        E.R.R. Mohan as the company’s Managing Director at the
                        age of 23. Despite his young age, Mohan exhibited a
                        remarkable ability to steer the company forward.
                        <br />
                        <br />
                        Under his guidance, ERR Constructions accomplished the
                        completion of not just two apartment complexes but also
                        two commercial complexes, demonstrating Mohan’s
                        commitment to expanding the firm’s portfolio.
                      </p>
                    </div>
                    <br />
                  </>
                )}
              </p>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
};

export default About;
