import React from 'react'
import { motion } from 'framer-motion';
import "../App.css";
import logo from "../logo.png";
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate } from 'react-router-dom';


const Navbar2 = () => {
  const navigate = useNavigate();
  const routeToGallery = () => {
    navigate('/gallery');
  }
  const routeToHome = () => {
    navigate('/');
  }
  return (
    <>
    <div className='bg-[#887d69]'>
    <nav className=" text-white p-8">
      <div className="container mx-auto flex justify-between items-center">
      <img src={logo} className='absolute top-2 left-15 size-12 sm:size-20'></img>
      <p className='pt-12 align-middle text-xs sm:text-sm'>ERR Associates</p>
      <ul className="flex space-x-4 ml-auto text-xs sm:text-sm">
      <ScrollLink to="home" smooth={true} duration={500} className="nav-link" onClick={routeToHome}>Home</ScrollLink>
            <ScrollLink to="services-section" smooth={true} duration={500} className="nav-link" onClick={routeToHome}>Services</ScrollLink>
            <ScrollLink to="about-section" smooth={true} duration={500} className="nav-link" onClick={routeToHome}>About</ScrollLink>
            <ScrollLink to="projects-section" smooth={true} duration={500} className="nav-link" onClick={routeToHome}>Projects</ScrollLink>
            <ScrollLink to="contact-section" smooth={true} duration={500} className="nav-link" onClick={routeToHome}>Contact</ScrollLink>
            <div className='nav-link' onClick={routeToGallery}>Gallery</div>
        </ul>
      </div>
    </nav></div>
    </>
  )
}

export default Navbar2